@import "../../../styles/variables";
@import "../../../styles/button";
@import "../../../styles/button_cancel";
.firmWareList {
  grid-area: content;
  width: 95%;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 32px;
  .firmWareListContainer {
    box-shadow:
      0 1px 0 0 rgba(63, 63, 68, 0.05),
      0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border-radius: 4px;
    padding: 32px;
    .firmWareListHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      .firmWareListTitle {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #646464;
        padding: 0;
        margin: 0;
      }
    }
    .updateFirmWareList {
      background-color: $Color7;
      border-radius: 4px;
      border: 1px solid $Color7;
      overflow-y: auto;
      scroll-snap-type: y mandatory;
      height: 400px;
      margin-top: 8px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: var(--grayscale-spacer-light, #646464);
      }
      li {
        cursor: pointer;
        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          padding: 0;
          margin: 0;
        }
        .version {
          padding-left: 8px;
        }
      }
      .list_btn {
        color: #215ca7;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 148%;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    @include Btn(10.25rem, 2.5rem);
  }
}
