@import "../../../../styles/variables";
@import "../../../../styles/title";
@import "../../../../styles/button";
.listRoles {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  .listRolesContainer {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .listRolesModalForm {
    width: 50rem;
    padding: 20px 32px 32px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow:
      0 1px 0 0 rgba(63, 63, 68, 0.05),
      0 4px 4px 0 rgba(0, 0, 0, 0.25);

    .listRolesModalFormHeader {
      .listRolesModalFormHeaderTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #646464;
        padding: 0;
        margin: 0;
      }
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .listRolesModalFormFooter {
      @include Btn(11.25rem, 2.5rem);
    }
    .back_roles {
      display: flex;
      width: 11.25rem;
      padding: 8px 28px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #646464;
      background: #fff;

      span {
        color: #646464;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 148%;
      }
    }
  }
}
