@import "variables";
@mixin Title($fz) {
  h2 {
    font-size: #{$fz}px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $Color8;
    padding: 0;
    margin: 0;
  }
}
