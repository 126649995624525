.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  left: 55%;
  bottom: 20px;
  transform: translateX(-55%) translateY(-30%);
  max-width: 100%;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(211, 192, 214, 0.07);
  span {
    color: #ffffff;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 36px;
  }
}
.notification_warning {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  left: 40%;
  bottom: 20px;
  transform: translateX(-40%) translateY(-40%);
  height: 60px;
  max-width: 100%;
  border-radius: 4px;
  background: #ff9500;
  box-shadow: 0 10px 15px 0 rgba(211, 192, 214, 0.07);
}
.white_line {
  border-left: 10px solid #ffffff;
  right: 50%;
  height: 100%;
  opacity: 0.2;
}
.text_warning {
  font-family: "Poppins", system-ui;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  color: #1a141f;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 36px;
}
