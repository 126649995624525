@import "../../../styles/variables";
@import "../../../styles/button";
@import "../../../styles/button_cancel";
@import "../../../styles/InputTextarea";
.usersList {
  grid-area: content;
  width: 95%;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 32px;
  .usersContainer {
    .usersHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .usersTitle {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #646464;
        padding: 0;
        margin: 0;
      }
    }
    .searchBlock {
      display: flex;
      border: 2px solid #e5e5e5;
      border-radius: 4px;
      background-color: $BgColor;
      align-items: center;
      input {
        @include InputTextarea(400px, 40px);
        padding: 0 32px 0 16px;
        color: $Color6;
        border-radius: 0 0 5px 5px;
        &:hover {
          cursor: pointer;
        }
      }
      svg {
        margin-right: 14px;
      }
      .btn-clear {
        border-radius: 0 0 5px 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .users_body {
      margin-top: 20px;
      box-shadow:
        0 1px 0 0 rgba(63, 63, 68, 0.05),
        0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background: #ffffff;
      border-radius: 4px;
      padding: 32px;
      header {
        justify-content: left;
        z-index: 1;
        padding: 0;
        margin-bottom: 20px;
      }
    }
    @include Btn(220px, 40px);
    @include BtnCancel(220px, 40px);
  }
}
