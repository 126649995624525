/*Переменные и общие стили menu, functionBlockContainer, transportMenu, tableContainer, editorBlockContainer*/
$BgColor: #ffff;
$BXShadow:
  0px 1px 0px 0px rgba(63, 63, 68, 0.05),
  0px 4px 4px 0px rgba(0, 0, 0, 0.25);
/*$Border: 3px solid rgba(63, 63, 68, 0.03);*/
$BorderRadius: 4px;

$BorderBottom: 1px solid #e4e7eb;
$Border2: 1px solid #e5e0eb;
/*Переменные для различных color*/
$Color1: #3a3b3f;
$Color2: #c4c7cd;
$Color3: #7b61ff;
$Color4: #66788a;
$Color5: #e4e7eb;
$Color6: #aba7af;
$Color7: #e5e5eb;
$Color8: #646464;
$Color9: #35a936;
$Color10: #ec4c47;
$Color11: #2684ff;
$Color12: #14191f;
$Color13: #f5f3f7;
$Color14: #1e5da8;
