@import "../styles/variables";
@mixin BtnLogin {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-right: 30px;
    padding: 8px 28px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: $Color9;
    height: 40px;
    font-style: normal;
    font-size: 16px;
    line-height: 148%;
    text-align: center;
    color: $BgColor;
  }
}
