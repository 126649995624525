.navBarContainer {
  grid-area: menu;
  z-index: 10;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.1);
  background: #ffff;
  .avatar {
    margin-top: 32px;
  }
  .companyOurName {
    margin-top: 8px;
    border-bottom: 1px solid #e4e7eb;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: -0.0364583px;
      color: #3a3b3f;
      text-align: center;
    }
    .job {
      @extend p;
      margin-bottom: 25px;
      margin-top: 8px;
      line-height: 12px;
      letter-spacing: 0.4px;
      color: #9ea0a5;
    }
  }
  .navigation {
    margin-top: 20px;
    li {
      margin-left: 25px;
      line-height: 2;
      margin-bottom: 8px;
      cursor: pointer;

      .navigation_point {
        align-items: center;
        svg {
          &.active {
            path {
              stroke: #7b61ff;
            }
          }
        }
        span {
          padding-left: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.0364583px;
          color: #646464;
          &.active {
            color: #7b61ff;
          }
          &:hover {
            text-decoration: underline;
            color: #7b61ff;
          }
        }
      }
    }
  }
}
