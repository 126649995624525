@import "../../../../styles/variables";
@import "../../../../styles/InputTextarea";
@import "../../../../styles/button";
.overlay {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .uploaderContainer {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-60%, -50%);
    .uploaderForm {
      border-radius: 0.25rem;
      width: 28.25rem;
      max-height: 500px;
      background-color: $BgColor;
      padding: 32px;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .uploaderHeader {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #646464;
      padding: 0;
      margin: 0;
    }
    .uploaderBlock {
      .uploaderBlockForm {
        display: flex;
        flex-direction: column;
        gap: 4px;
        label {
          font-weight: 700;
          padding-bottom: 0.25rem;
          font-size: 1rem;
          line-height: 148%;
          color: $Color1;
        }
        input {
          @include InputTextarea(100%, 2.75rem);
          border: 1px solid #646464;
          padding: 12px;
          border-radius: 4px;
        }
      }
      p {
        color: #ec4c47;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }

    @include Btn(100%, 2.5rem);
  }
}
