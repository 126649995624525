@import "../../../styles/variables";
@import "../../../styles/button";
.companyList {
  grid-area: content;
  width: 95%;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 32px;
  .companyContainer {
    .companyHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      .companyTitle {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #646464;
        padding: 0;
        margin: 0;
      }
    }
    .company_body {
      margin-top: 40px;
      box-shadow:
        0 1px 0 0 rgba(63, 63, 68, 0.05),
        0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background: #ffffff;
      border-radius: 4px;
      padding: 32px;
    }
    @include Btn(10.25rem, 2.5rem);
  }
}
