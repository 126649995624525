@import "../../../../styles/variables";

.overlay {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .portsContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .portsForm {
      border-radius: 0.25rem;
      width: 400px;
      max-height: 600px;
      background-color: $BgColor;
      padding: 24px;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .portsHeader {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #646464;
      padding: 0;
      margin: 0;
    }
    .ports_form_body {
      margin-top: 28px;
      span {
        padding-left: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.0364583px;
        color: $Color4;
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: $Color9;
        line-height: 24px;
      }
    }
  }
}
