@import "../../../../styles/variables";
@import "../../../../styles/InputTextarea";
@import "../../../../styles/button";
@import "../../../../styles/button_cancel";

.overlay {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .createContainer {
    width: 100%;
    min-width: 320px;
    max-width: 46%;
    max-height: 90vh;
    background-color: #fff;
    border-radius: 8px;
    overflow-y: auto;

    .createEditForm {
      border-radius: 0.25rem;
      background-color: $BgColor;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .createHeader {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #646464;
      padding: 0;
      margin: 0;
    }
    .createBlock {
      .createBlockForm {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 700;
          padding-bottom: 0.25rem;
          font-size: 1rem;
          line-height: 148%;
          color: $Color1;
        }
        input,
        textarea {
          @include InputTextarea(100%, 2.75rem);
          border: 1px solid #646464;
          padding: 12px;
          border-radius: 4px;
        }
        .input__error {
          border: 2px solid #ec4c47;
        }
      }
      p {
        color: #ec4c47;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 148%;
        margin: 0;
        padding: 0;
      }
      .createButton {
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;

        @include Btn(180px, 40px);
        @include BtnCancel(180px, 40px);
      }
    }
  }
}

.collapsible-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    max-height 0.7s ease,
    opacity 0.7s ease;

  &.expanded {
    max-height: 1000px;
    opacity: 1;
  }
}
