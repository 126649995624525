@import "../../styles/title";
@import "../../styles/button_login";
.entryPage {
  grid-template-columns: 1fr minmax(200px, 400px) 1fr;
  grid-template-rows: 1fr minmax(auto, 1fr) 2fr;
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/image/background/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: relative;

  .entryPageLogo {
    grid-row: 1;
    grid-column: 2;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  form {
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
    grid-column: 2;
    grid-row: 2;
    display: grid;
    margin: auto 0;
    background: #ffffff;
    box-shadow:
      0 0 0 rgba(63, 63, 68, 0.05),
      0 0.729167px 2.1875px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    @include Title(24);
    h2 {
      padding-bottom: 8px;
      text-align: left;
      font-weight: 700;
      line-height: 20px;
      color: #212529;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.004em;
      color: #9ea0a5;
    }
    fieldset {
      margin-top: 8px;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: grid;
          align-items: center;
          padding-bottom: 8px;

          em {
            grid-column: span 2;
            text-align: center;
            padding: 5px;
          }
          label {
            text-align: left;
            padding-bottom: 2px;
          }
          input {
            margin-right: 30px;
            padding: 12px;
            border: 1px solid #e5e5eb;
            border-radius: 2px;
            cursor: pointer;
            outline: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #1a141f;
            &:hover {
              border: 1px solid #aaf;
            }
          }
          p {
            color: #ec4c47;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
          }
        }
      }
    }
    @include BtnLogin;
    a {
      color: #1665d8;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
