@import "variables.scss";

@mixin InputTextarea($width, $height) {
  width: #{$width};
  height: #{$height};
  background: $BgColor;
  font-weight: 400;
  font-size: 0.875rem;
  outline: none;
  line-height: 140%;
  cursor: pointer;
}
