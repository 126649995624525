@import "../styles/variables";

@mixin BtnCancel($width, $height) {
  .cancel {
    margin-left: auto;
    width: #{$width};
    height: #{$height};
    border-radius: 4px;
    background: $Color10;
    justify-content: center;
    align-items: center;
    padding: 8px 28px;
    span {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      line-height: 148%;
      color: $BgColor;
    }
    &:hover {
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px $Color10;
      }
    }
  }
}
