.wrapper {
  display: grid;
  box-sizing: border-box;
  height: 100vh;
  grid-template-areas:
    "header header"
    "menu content";
  grid-template-rows: 46px 1fr;
  grid-template-columns: 300px 1fr;
}
