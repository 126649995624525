@import "../styles/variables";

@mixin BtnModal {
  .modalButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      width: 180px;
      padding: 8px 28px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $Color8;
      background: #fff;
      span {
        color: #646464;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 148%;
      }
      &:hover {
        box-shadow: 0 0 2px 2px $Color7;
      }
    }
  }
}
