@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafb;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

* {
  font-family: "Roboto", system-ui;
  list-style: none;
  padding: 0;
  margin: 0;
}
