header {
  grid-area: header;
  background-color: #1e5da8;
  height: 2.875rem;
  z-index: 13;

  .headerLogo {
    margin-left: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
