.sessionList {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.sessionListContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sessionListModalForm {
  width: 800px;
  height: 400px;
  padding: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow:
    0 1px 0 0 rgba(63, 63, 68, 0.05),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  .sessionListModalFormHeader {
    .sessionListModalFormHeaderTitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #646464;
      padding: 0;
      margin: 0;
    }
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .sessionListModalFormBody {
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    height: 300px;
    margin-top: 20px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: var(--grayscale-spacer-light, #e5e0eb);
    }
  }
}
